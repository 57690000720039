<template>
    <div class="bg-custom-blue h-20 flex flex-col justify-center gap-y-2">
        <div class="flex container mx-auto justify-center items-center gap-x-7">
            <a class='text-3xl' href="https://github.com/mauro-arias" target="_blank"><i class="ai-github-fill text-white"></i></a>
            <a class='text-3xl' href="https://www.linkedin.com/in/mauro-arias-charras/" target="_blank"><i class="ai-linkedin-box-fill text-white"></i></a>
            <a class='text-3xl' href="https://api.whatsapp.com/send?phone=543512333859" target="_blank"><i class="ai-whatsapp-fill text-white"></i></a>
        </div>
        <div class="flex container mx-auto justify-center items-center">
            <p class='text-xs text-white'>Mauro Arias &copy; {{ new Date().getFullYear() }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
    a:hover i{
        color: rgba(124, 153, 172, var(--tw-text-opacity));
        transition: ease-in .1s;
    }
</style>