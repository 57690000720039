<template>
    <main class='sm:mb-0 h-screen portada'>
        <div class="grid sm:grid-cols-2 grid-cols-1 container mx-auto">
            <div class='flex flex-col justify-center gap-y-5 sm:items-start items-center'>
                <h2 class='text-custom-darkgray text-xl'>
                    <span class='block sm:text-left text-center'>Hola, soy</span>
                    <span class='text-custom-blue text-4xl font-medium block sm:text-left text-center' id='texto'></span>
                    Desarrollador Front-End
                </h2>
                <p id='texto'></p>
                <div class="flex gap-x-2 flex-wrap sm:justify-start justify-center gap-y-2">
                    <a target="_blank" href="/mauroarias-cv.pdf" class='primary-btn w-44 p-2 flex justify-center items-center'><i class="ai-file mr-1"></i>Visualizar CV</a>
                    <router-link :to="{name: 'Projects'}" class='secondary-btn w-44 p-2 flex justify-center items-center'><i class="ai-coffee mr-1"></i>Ver mis proyectos</router-link>
                </div>
            </div>
            
            <div class='order-first sm:order-last'>
                <img src="../assets/img/portfolio.svg" alt="Portfolio">
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'Home',
    mounted(){
        var app = document.getElementById('texto');
        var typewriter = new Typewriter(app, {
        delay: 90,
        });
        typewriter
        .typeString('Mauro Arias.')
        .start();
    }
}
</script>

<style scoped>
    .portada{
        background-image: url("../assets/img/portada.svg");
        background-size: cover;
        background-position: center;
    }
</style>