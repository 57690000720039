<template>
  <nav>
    <div class="w-full px-4 md:px-10">
      <div class="flex justify-between">
        <div class="flex space-x-7">
          <div>
            <h1 class="flex items-center py-4 px-2"><span class="font-medium text-custom-lightblue text-xl">Mauro Arias</span>
            </h1>
          </div>
        </div>
        <div class="hidden md:flex items-center space-x-8">
          <router-link class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue" :to="{name: 'Home'}">Inicio</router-link>
          <router-link class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue" :to="{name: 'About'}">Sobre Mi</router-link>
          <router-link class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue" :to="{name: 'Projects'}">Proyectos</router-link>
          <router-link class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue" :to="{name: 'Contact'}">Contacto</router-link>
        </div>
        <div class="mr-5 flex md:hidden">
          <button
            class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-dark
            "
            @click='toggleNavigation'
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div :class="[this.navOpen === false ? 'hidden' : '']">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3 md:hidden shadow-lg">
        <router-link @click='this.navOpen = false' class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue block" :to="{name: 'Home'}">Inicio</router-link>
        <router-link @click='this.navOpen = false' class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue block" :to="{name: 'About'}">Sobre Mi</router-link>
        <router-link @click='this.navOpen = false' class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue block" :to="{name: 'Projects'}">Proyectos</router-link>
        <router-link @click='this.navOpen = false' class="text-sm py-4 px-2 text-custom-blue transition duration-150 hover:text-custom-lightblue block" :to="{name: 'Contact'}">Contacto</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data(){
      return {
          navOpen: false
      }
  },
  methods: {
      toggleNavigation(){
          this.navOpen = !this.navOpen
      }
  },
};
</script>

<style>

 nav .router-link-active,
 nav .router-link-exact-active {
   color: rgba(124, 153, 172);
 }
</style>